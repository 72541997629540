import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy,Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy,ToastController,LoadingController } from '@ionic/angular';
import {ModalController,AlertController,PopoverController } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient,HttpClientModule,HttpHeaders } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';

import { Location,HashLocationStrategy, LocationStrategy } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BehaviorSubject, interval } from 'rxjs';
import { first, subscribeOn, windowWhen } from 'rxjs/operators';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { File } from '@ionic-native/file/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [HttpClientModule,BrowserModule, IonicModule.forRoot(), AppRoutingModule,BrowserAnimationsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
              { provide: LocationStrategy, useClass: HashLocationStrategy},
              { provide: Network},BarcodeScanner,File,SocialSharing ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public jsonScheda: any;
  public jsonCrypt: any;
  public jsonMail: any;
  public WS_Server:any=
                  [ {id: 26973,cliente: 'Europa Multiservice',path_ws: 'https://servizi.europamultiservice.it:8020/ProLavWeb/',path_web: 'https://servizi.europamultiservice.it/ProLavID/#',logo:'assets/Loghi/logo_europa.png'},
                    {id: 37725,cliente: 'Tutonet Lavanderia Industriale',path_ws: 'http://web.tutonet.it:8081/ProLavWeb/',path_web: 'http://web.tutonet.it/ProLavID/#',logo:'assets/Loghi/logo_tutonet.png'},
                    /*{id: 84931,cliente: 'Lim Lavanderia Industriale Mappanese',path_ws: 'http://192.168.0.83:8081/ProLavWeb/',path_web: 'http://35.180.214.38/ProLavID/#',logo:'assets/Loghi/logo_prolavid.png'},
                    {id: 46239,cliente: 'Marawash Lavanderia Industriale',path_ws: 'http://192.168.0.83:8081/ProLavWeb/',path_web: 'http://35.180.214.38/ProLavID/#',logo:'assets/Loghi/logo_prolavid.png'},
                    {id: 34921,cliente: 'Lidi Group',path_ws: 'http://192.168.0.83:8081/ProLavWeb/',path_web: 'http://35.180.214.38/ProLavID/#',logo:'assets/Loghi/logo_prolavid.png'},
                    {id: 50917,cliente: 'Lavanderia Industriale Cipelli ',path_ws: 'http://192.168.0.83:8081/ProLavWeb/',path_web: 'http://35.180.214.38/ProLavID/#',logo:'assets/Loghi/logo_prolavid.png'},                    
                    {id: 11111,cliente: 'Emil Software',path_ws: 'http://192.168.0.83:8081/ProLavWeb/',path_web: 'http://35.180.214.38/ProLavID/#',logo:'assets/Loghi/logo_prolavid.png'},*/
                    {id: 99999,cliente: 'Prolav ID',path_ws: 'http://35.180.214.38:8083/ProLavWeb/',path_web: 'http://35.180.214.38/ProLavID/#',logo:'assets/Loghi/logo_prolavid.png'}
                    ];


  public connected$ = new BehaviorSubject<boolean>(false);
  public connState: boolean;
  private bConnMsgErr: boolean;
  private source = interval(60000);

  APP_MAIL = 'noreply@prolav.it';
  //stati di registrazione
  STAREG_NULL  = 0;   //nessun account
  STAREG_INVIO = 10;  //inviata la mail di conferma
  STAREG_CONF  = 20;  //email confermata
  STAREG_BLOCC = 99;  //utente bloccato

  //codici di errore
  ERROR_ZEROREC = 1;  //nessun record trovato

  //codice esecuzione sql
  EXEC_KEY = btoa('101104-260170');

  //chiave aes128
  AES128_KEY= btoa("Prolav-Web");

  //pattern per controlli form
  PATTERN_EMAIL = "[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})";
  PATTERN_PWD   = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&_]{8,}$"

  currentModal: HTMLIonModalElement;

  WS_HEADER = {
    headers: new HttpHeaders({
      //'Authorization':  'Basic UHJvbGF2LVdlYg==',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Method': 'GET,POST,OPTIONS,DELETE,PUT',
    })
  };

  constructor(public toastController:ToastController,
    public loadingController: LoadingController,
    public modalController: ModalController,
    public popoverCtrl: PopoverController,
    public alertController:AlertController,
    private network: Network,
    private router: Router,
    private location: Location,
    private http: HttpClient){

    //  localStorage.setItem('WS_PATH','');
    //  localStorage.setItem('PIN','');

      if (localStorage.getItem('PIN')!='') {
        this.Get_Logo(parseInt(localStorage.getItem('PIN')));
      }

      this.source.subscribe(() => {
        if (this.Get_WS_PATH()!='') {
          this.http.get(this.Get_WS_PATH()+'Ping', { observe: 'response' })
            .pipe(first())
            .subscribe(resp => {
                          if (resp.status === 200) {
                            this.connected(true);
                            this.bConnMsgErr = false;
                          } else {
                            this.connected(false);
                            if (!this.bConnMsgErr) {
                              this.presentToast('Server non disponibile.','danger');
                            this.bConnMsgErr = true;
                            }
                          }
                        },error => {
                          this.connected(false);
                          if (!this.bConnMsgErr) {
                            this.presentToast('Server non disponibile.','danger');
                          this.bConnMsgErr = true;
                          }
                        }
            )
          }
      });

      this.connected$.subscribe(connected => {
        console.log("Connected: ", connected);
      });
    }  
    

  connected(data: boolean) {
    this.connState = data;
    this.connected$.next(this.connState);
  }

  //verifica la presenza della connesione, funziona solo per i dispositivi mobili, non per i browser
  public disconnectSubscription = this.network.onDisconnect().subscribe(async() => {
    await this.presentToast('Nessuna connessione attiva.','danger');
  });

  public GetLSItem(sItem:string):string{
    return localStorage.getItem(sItem);
  }

  //***********************************************************/
  // in base al CF fiscale inserito o allo usn/pwd inserito la
  // prima volta, verifica su quale server c'è l'utente e mette
  // nel localstorage il percorso del webservice
  //***********************************************************/
  public async Set_WS_SERVER(sCF:string,sUsn:string,sPwdAES128:string,sPwd:string){
    var sql:string;
    var bFound:boolean=false;
  
    if (sCF!='') {
      sql = "SELECT * FROM PERSONALE WHERE CF='"+sCF+"'";
    }
    if (sUsn!='' || sPwd!=''){
      sql = "SELECT IDXPERSONALE AS IDX ";
      sql = sql + "FROM PERSONALE_LOGIN ";
      sql = sql + "WHERE UPPER(USERNAME)='"+sUsn+"' AND PWD = '"+sPwdAES128+"' ";
      sql = sql + "UNION ";
      sql = sql + "SELECT IDX ";
      sql = sql + "FROM UTENTI ";
      sql = sql + "WHERE UPPER(UNAME)='"+sUsn+"' AND PWORD = '"+sPwd+"'";
    }

    async function Check_Server(http:any,sPath:string,sHeader:any){ 
      let response = await http.post(sPath + 'OpenQuery64', 'SQL=' + btoa(sql), sHeader).toPromise();
      let result = JSON.stringify(await response);
      var jsons = JSON.parse(result);            
      return  jsons.Status.errorCode==0;         
    }
   
    for (var i=0;i<this.WS_Server.length;i++){      
      let b = await Check_Server(this.http,this.WS_Server[i].path_ws,this.WS_HEADER);
      console.log(this.WS_Server[i].path_ws + ' ---> '+b);
      if (b) {
        bFound = b;
        this.Get_WSADDR(this.WS_Server[i].id);
        break;
      }
    }

    if (!bFound) {
      this.presentAlert('Attenzione!','Il server non è stato trovato. Impossibile continuare.');
    }

    return bFound
  }

  public Get_WS_PATH():string{
    return this.Get_WSADDR(parseInt(localStorage.getItem('PIN')));
  }

  public Get_WSADDR(iPin:number):string{
    var sWS:string='';

    if (iPin>0) {
      if (this.WS_Server.find(obj => obj.id === iPin)) {
        sWS = this.WS_Server.find(obj => obj.id === iPin).path_ws;
        localStorage.setItem('WS_PATH',sWS);
        localStorage.setItem('PIN',iPin.toString());
        this.Get_Logo(iPin);
        this.Get_Cliente(iPin);
        this.Get_WWW(iPin);
        console.log('WS_PATH......'+sWS);
      }
    }
    return sWS;
  }

  Get_Logo(iPin:number){
    if (iPin>0) {
      if (this.WS_Server.find(obj => obj.id === iPin)) {
        let sLogoImg:string;
        sLogoImg = '../../'+this.WS_Server.find(obj => obj.id === iPin).logo;
        localStorage.setItem('LOGO',sLogoImg);        
      }
    }
  }

  public Get_Cliente(iPin:number):string{
    var sCli:string='';
    if (iPin>0) {
      if (this.WS_Server.find(obj => obj.id === iPin)) {
        sCli = this.WS_Server.find(obj => obj.id === iPin).cliente;
        localStorage.setItem('CLIENTE',sCli);
      }
    }
    return sCli;
  }

  public Get_WWW(iPin:number):string{
    var sWWW:string='';
    if (iPin>0) {
      if (this.WS_Server.find(obj => obj.id === iPin)) {
        sWWW = this.WS_Server.find(obj => obj.id === iPin).path_web;
        localStorage.setItem('PATH_WEB',sWWW);
      }
    }
    return sWWW;
  }

  public GetMenu(sMenu:string):boolean{
    let arMenu:any=[];
    arMenu = JSON.parse(localStorage.getItem("jMenuAccesso"));
    return arMenu.Result.find(s => atob(s.MENU).toUpperCase() === sMenu.toUpperCase());
  }

  GoBack() {
    this.location.back();
  }

  DoData(value:string){
    var s:string='';
    if (value) {
      s = atob(value);
    }
    return s;
  }

  GetPackingList(){
    this.http.post(this.Get_WS_PATH()+'PckListGet', 'idx='+this.GetLSItem('iIdxSchede'), this.WS_HEADER).subscribe
    ( data => {
      this.jsonScheda = data;
      var errorCode = this.jsonScheda.Status.errorCode;
      if(errorCode == 0){
        localStorage.setItem('sCognome',atob(this.jsonScheda.Personale[0].COGNOME));
        localStorage.setItem('sNome',atob(this.jsonScheda.Personale[0].NOME));
        localStorage.setItem('sReparto',atob(this.jsonScheda.Personale[0].DESREP));
        localStorage.setItem('sMansione',atob(this.jsonScheda.Personale[0].MANSIONE));
        localStorage.setItem('sConsegna',atob(this.jsonScheda.Personale[0].CONSEGNA));
        localStorage.setItem('sCliente',atob(this.jsonScheda.Personale[0].CLIENTE));
        localStorage.setItem('iFlgPaz',this.jsonScheda.Personale[0].FLGPAZ);
      }
      else {
        this.presentAlert('Errore',this.jsonScheda.Status.errorDescription);
      }

    });

  }

  CryptAES128(sStringa:string){
    var sVal = '';
    if (sStringa!=''){
      this.http.post(this.Get_WS_PATH()+'CryptAES128', 'VALUE='+btoa(sStringa)+'&KEY='+this.AES128_KEY, this.WS_HEADER).subscribe
      ( data => {
        this.jsonCrypt = data;
        var errorCode = this.jsonCrypt.Status.errorCode;
        if(errorCode == 0){
          sVal = atob(this.jsonCrypt.Value);
          console.log('*** '+sVal);

        }
      });
    }
    return sVal;
  }

  SendMail(sTo:string,sCC:string,sBcc:string,sTitle:string,sBody:string){
    this.http.post(this.Get_WS_PATH()+'SendMail', 'From='+btoa(this.APP_MAIL)+'&To='+btoa(sTo)+'&CC='+btoa(sCC)+'&BCC='+btoa(sBcc)+'&Body='+btoa(sBody)+'&Title='+btoa(sTitle), this.WS_HEADER).subscribe
      ( data => {
        this.jsonMail = data;
        var errorCode = this.jsonMail.Status.errorCode;
        if(errorCode == 0){
          this.presentToast('Una mail è stata inviata a '+this.jsonMail.Status.errorDescription,'success');
        }
        else {
          this.presentAlert('Errore!',this.jsonMail.Status.errorDescription);
        }
      });
  }

  GetTextMailReg(sNominativo:string,sKeyReg:string){
    var sText:string;
    sText = 'Gentile <strong>'+sNominativo+'</strong>, ';
    sText = sText + '<p>ricevi questa mail perch&egrave; ti sei iscritto al servizio <strong>ProLav ID</strong>.</p> ';
    sText = sText + '<p>Clicca sul link sottostante per confermare la tua mail ed accedere al servizio.</p> ';
    sText = sText + '<a href="'+localStorage.getItem('PATH_WEB')+'/keyreg?key='+sKeyReg+'">Conferma indirizzo e-mail</a>';
    return sText;
  }

  async presentAlert(sHeader:string,sMsg:string) {
    const alert = await this.alertController.create({
      header: sHeader,
      message: sMsg,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  async presentToast(msg:string,BGColor:string) {
    const toast = await this.toastController.create({
      color: BGColor,
      duration: 3000,
      message: msg,
      buttons: [{
        text: 'Chiudi',
        role: 'cancel'
      }]
    });
    toast.present();
  }

  async presentLoading(msg:string) {
    const loading = await this.loadingController.create({
      message: msg,
      translucent: true,
    });
    return await loading.present();
  }

  async presentAlertPrompt(sHeader:string,sPlaceHolder:string) {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: sHeader,
        inputs: [
          {
            name: 'text1',
            type: 'text',
            placeholder: sPlaceHolder
          }
        ],
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
            handler: () => {
              return resolve('');
            },
          }, {
            text: 'Conferma',
            handler: (alertData) => {
              return resolve(alertData.text1);
            }
          }
        ]
      });
      await alert.present();
    });
  }

  async presentConfirm(sHeader:string,sMsg:string) {
    return new Promise(async (resolve) => {
      const confirm = await this.alertController.create({
        header: sHeader,
        message: sMsg,
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
            handler: () => {
              return resolve(false);
            },
          },
          {
            text: 'Conferma',
            handler: () => {
              return resolve(true);
            },
          },
        ],
      });
      await confirm.present();
    });
  }

  MakeRandom(lengthOfCode: number) {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    console.log(text);
    return text;
  }

}
