import { Component,OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppModule } from '../app.module';

@Component({
  selector: 'app-account',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
})
export class AccountPage  implements OnInit  {

  constructor(private MyApp : AppModule,
              private modalController: ModalController) { }

  ngOnInit() {
  }

  async closeModel() {    
    await this.modalController.dismiss();
  }

}
