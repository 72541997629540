import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'articoli',
    loadChildren: () => import('./articoli/articoli.module').then( m => m.articoliPageModule)
  },  
  {
    path: 'personale',
    loadChildren: () => import('./personale/personale.module').then( m => m.PersonalePageModule)
  },
  {
    path: 'cerca',
    loadChildren: () => import('./cerca/cerca.module').then( m => m.CercaPageModule)
  },
  {
    path: 'keyreg',
    loadChildren: () => import('./keyreg/keyreg.module').then( m => m.KeyregPageModule)
  },
  {
    path: 'recupero',
    loadChildren: () => import('./recupero/recupero.module').then( m => m.RecuperoPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'aiuto',
    loadChildren: () => import('./aiuto/aiuto.module').then( m => m.AiutoPageModule)
  },
  {
    path: 'carrelli',
    loadChildren: () => import('./carrelli/carrelli.module').then( m => m.CarrelliPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
