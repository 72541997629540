import { Component } from '@angular/core';
import { Router} from '@angular/router'; 
import { ModalController } from '@ionic/angular';
import { AccountPage } from './account/account.page';
import { AppModule } from './app.module';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(public modalController: ModalController,
              private MyApp : AppModule,
              private router: Router) {
  }


  async GoToAccount() {
    const modal = await this.modalController.create({
      component: AccountPage,
      cssClass: 'my-custom-modal-css'
    });
    return await modal.present();
  }


  disconnect(){
    this.router.navigateByUrl('/login?d=1'); 
  }


}
